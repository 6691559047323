@import '../variables.module.scss';

@include font-imports('../');

.Root {
  // height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 100%;
}

.Content {
  display: flex;

  flex-direction: column;
  height: 100%;

  padding: $padding-small;

  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}

.Navbar {
  flex: 0 1 100px;
}

.Welcome {
  // flex: 0 1 30%;
  align-self: center;
  text-align: center;
  justify-self: flex-start;

  min-width: 100%;

  padding: $padding-small 3 * $padding-large;

  font-family: map-get($font-family, Sans);
  margin-bottom: $padding-small;
  border-radius: $border-radius;

  // background-color: map-get($background-colors, Alt);
  // color: map-get($font-colors, Alt);
  color: map-get($font-colors, Main);

  // border-top: 8px solid map-get($font-colors, Main-Accent);
  // border-bottom: 8px solid map-get($font-colors, Main-Accent);
  box-sizing: border-box;

  z-index: 2;

  @include for-size(phone-only) {
    & div,
    & span {
      display: block;
    }
  }

  @include for-size(tablet-portrait-up) {
    font-size: 1em;
    min-width: 80%;
  }

  @include for-size(tablet-portrait-up) {
    font-size: 1.5em;
    min-width: auto;
  }

  @include for-size(desktop-up) {
    font-size: 2em;
    margin-bottom: 3 * $padding-large;
  }

  & .Cursive {
    margin: 0 $padding-large;
    font-size: 2.5em;
    font-family: map-get($font-family, Cursive);

    @include for-size(tablet-portrait-up) {
      font-size: 1.7em;
    }

    @include for-size(tablet-landscape-up) {
      font-size: 2.5em;
    }
  }

  & > .Names {
    @include for-size(phone-only) {
      margin-bottom: $padding-large;
    }
  }
}

.Form {
  display: flex;
  justify-content: center;
  width: 100%;

  @include for-size(tablet-portrait-up) {
    width: 80%;
  }

  @include for-size(tablet-landscape-up) {
    width: 60%;
  }

  @include for-size(desktop-up) {
    .Narrow {
      width: 40%;
    }
  }
}
