@import '../variables.module';

.ExtraInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.4em;
}

.ExtraInfo {
  width: 100%;

  @include for-size(tablet-landscape-up) {
    width: 50%;
  }
}

.Map {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;

  min-height: 500px;
  margin-bottom: $padding-large;
}

.CeremonyMap {
  background-image: url('../images/ceremony-map.png');
}

.EveningMap {
  background-image: url('../images/evening-map.png');
}

.DirectionsContainer {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column-reverse;

  & > * {
    flex: 1 1 100%;
    align-items: stretch;
  }
}

.Directions {
  // padding: $padding-medium;

  @include for-size(tablet-landscape-up) {
    flex-direction: row;
  }

  @include for-size(tablet-landscape-up) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.TaxiNumberTable {
  width: 100%;
  margin-top: $padding-large;
  & tr {
    min-width: 50%;
  }
}
