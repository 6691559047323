@import './variables.module.scss';

@include font-imports('./');

.Root {
  height: 100vh;
  width: 100vw;
  background-color: map-get($background-colors, Main);
  color: map-get($font-colors, Main);
  background-image: url('./images/splash-min.png');
  background-position: 0% 0%;
  background-repeat: no-repeat;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.Content {
  flex: 1 1 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  padding: $padding-small;
  box-sizing: border-box;
}
