$background-colors: (
  Main: #313c45,
  Alt: #fcf8f7,
  Alt-Accent: #f4e3e1,
);

$font-colors: (
  Main: #fcf8f7,
  Main-Accent: #f4e3e1,
  Alt: #313c45,
);

// @font-face {
//   font-family: 'HiLoDeco';
//   src: url('./HiLo-Deco.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Roselyne';
//   src: url('./Roselyne TTF.ttf') format('truetype');
// }

// $font-family: (
//   Sans: 'HiLoDeco',
//   Cursive: 'Roselyne'
// );

@mixin font-imports($path-to-src) {
  @font-face {
    font-family: 'HiLoDeco';
    src: url($path-to-src+'HiLo-Deco.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Roselyne';
    src: url($path-to-src+'Roselyne TTF.ttf') format('truetype');
  }
}

$font-family: (
  Sans: 'HiLoDeco',
  Cursive: 'Roselyne',
);

$padding-small: 8px;
$padding-medium: $padding-small * 1.5;
$padding-large: $padding-small * 2;

$border-radius: 3px;

@mixin for-size($size) {
  @if ($size == phone-only) {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}

@mixin Link() {
  .Link {
    color: map-get($font-colors, Main-Accent);

    &:visited {
      color: map-get($font-colors, Main-Accent);
    }
  }
}
