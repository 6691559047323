@import '../variables.module.scss';

.Root {
  // display: flex;
  // flex-direction: column;
  align-items: center;
}

.GuestForm {
  border: none;
  padding: $padding-small;
  margin-bottom: $padding-large;
  display: flex;
  justify-content: center;
}

.PageTitle {
  align-self: flex-start;
}

.Invitation {
  position: relative;
  background-image: url('../images/bouquet9.png');
  background-repeat: no-repeat;
  background-position: 30% 0;
  background-size: 50%;

  $larger-width: 60%;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include for-size(tablet-landscape-up) {
    flex-direction: row;
    padding-top: $padding-large * 2;
    padding-bottom: $padding-large * 2;
    width: 80%;
  }

  @include for-size(desktop-up) {
    flex-direction: row;
    padding-top: $padding-large * 2;
    padding-bottom: $padding-large * 2;
    width: 60%;
  }

  & > .CircleGoing {
    @include for-size(tablet-landscape-up) {
      width: 100 - $larger-width;
      position: relative;
      top: 1em;
      align-self: flex-start;
    }
  }

  & > .CircleOtherInfo {
    position: relative;
    @include for-size(tablet-landscape-up) {
      width: $larger-width;
      align-self: flex-end;
    }
  }

  & > .Bouquet {
    position: absolute;
    align-self: center;
    justify-self: center;
  }
}

.SubmitMessage {
  width: 100%;
  align-self: center;

  @include for-size(tablet-landscape-up) {
    width: 70%;
  }

  @include for-size(desktop-up) {
    width: 50%;
  }
}
