@import '../variables.module.scss';

@include font-imports('../');

.Root {
  // width: 90%;
  width: 100%;

  .Label {
    display: block;
    font-size: 1em;
    // color: #bca973;
    color: map-get($font-colors, Alt);
    // font-weight: bold;
    font-family: map-get($font-family, Sans);
    margin-bottom: $padding-small;
    font-weight: bold;

    &.BgMain {
      color: map-get($font-colors, Main);
    }

    // @include for-size(phone-only) {
    //   display: none;
    // }

    // @include for-size(tablet-landscape-up) {
    //   font-size: 2em;
    // }

    // @include for-size(desktop-up) {
    //   font-size: 2.5em;
    // }
  }

  & .Input {
    resize: none;

    width: 100%;
    border: none;
    border-radius: $border-radius;
    background-color: map-get($background-colors, Alt);
    // background-color: map-get($background-colors, Alt-Accent);
    color: map-get($font-colors, Alt);
    font-family: map-get($font-family, Sans);
    margin: 0;
    margin-bottom: $padding-large;
    padding: $padding-small;
    box-sizing: border-box;

    &:focus {
      outline: none;
    }

    // border-bottom: 8px solid map-get($font-colors, Main-Accent);
    // border-top: 8px solid map-get($font-colors, Main-Accent);

    &.Medium {
      font-size: 1.25em;
    }

    &.Large {
      // height: 70px;
      font-size: 1.25em;

      @include for-size(tablet-landscape-up) {
        font-size: 2.5em;
      }
    }

    &.AlignCenter {
      text-align: center;
    }

    &.AlignLeft {
      text-align: left;
    }

    &.BgAlt {
      background-color: map-get($background-colors, Alt-Accent);
    }
  }
}
