@import '../variables.module';

.Root {
  flex: 0 1 auto;
  width: 100%;
  z-index: 2;
  padding: $padding-small;
  border-radius: $border-radius;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  // @include for-size(tablet-portrait-up) {
  //   width: 80%;
  // }

  // @include for-size(tablet-landscape-up) {
  //   width: 60%;
  // }

  // @include for-size(desktop-up) {
  //   .Narrow {
  //     width: 40%;
  //   }
  // }
}
