@import '../variables.module';

@include font-imports('../');

@include Link;

.Root {
  color: map-get($font-colors, Main);
  font-family: map-get($font-family, Sans);
  flex: 1 1 100%;
  justify-self: stretch;
  align-self: stretch;
  display: flex;
}

.Container {
  display: flex;
  align-items: stretch;
  flex: 1 1 100%;
  flex-direction: column;

  @include for-size(tablet-landscape-up) {
    flex-direction: row;
  }
}

.Content {
  flex: 1 1 100%;
  font-size: 1.25em;
  z-index: 3;
}

.Image {
  background-image: url('../images/jf.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 40%;
  border-radius: 50%;

  &.ImageFlex {
    flex: 1 1 100%;
    display: none;

    @include for-size(tablet-landscape-up) {
      display: block;
    }
  }

  &.ImageContent {
    float: right;
    width: 40vw;
    height: 40vw;
    @include for-size(tablet-landscape-up) {
      display: none;
    }
  }
}

.Paragraph {
  margin-bottom: $padding-medium;
}
