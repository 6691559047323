@import '../variables.module';

@include font-imports('../');

.Root {
  color: map-get($font-colors, Main);
  font-family: map-get($font-family, Sans);
  flex: 1 1 100%;
  justify-content: center;

  display: flex;
}

.Container {
  // display: flex;
  // flex: 0 0 80%;
  // flex-direction: column;
  width: 80%;
  font-size: 1.4em;
  z-index: 3;
  // justify-content: center;

  @include for-size(tablet-landscape-up) {
    // flex: 0 0 50%;
    width: 50%;
  }
}

.Info {
  margin-bottom: $padding-large;
  flex: 0 1 100%;
}

.BouquetLargeScreen {
  display: none;
  @include for-size(tablet-landscape-up) {
    display: block;
  }
}

.Heading {
  text-align: center;
}

.CenterLink {
  margin-top: $padding-large;
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;

  & > a,
  & > a:visited {
    color: map-get($font-colors, Main-Accent);
  }
}
