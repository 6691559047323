@import '../variables.module.scss';

@include font-imports('../');

.Root {
  position: relative;
  width: 100%;
  display: inline-block;
  font-family: map-get($font-family, Sans);

  @include for-size(tablet-landscape-up) {
    &:before {
      display: block;
      content: '';
      width: 100%;

      $height: 1;
      $width: 1;

      padding-top: ($height / $width) * 100%;
    }
  }

  background-size: cover;
}

.Content {
  display: flex;
  flex-direction: column;

  @include for-size(tablet-landscape-up) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
  }
}

.Container {
  position: relative;
  width: 100%;

  @include for-size(tablet-landscape-up) {
    &:before {
      display: block;
      content: '';
      width: 100%;

      $height: 1;
      $width: 1;

      padding-top: ($height / $width) * 100%;
    }
  }
}

.Box {
  background-color: map-get($background-colors, Alt);
  border: 5px solid map-get($font-colors, Main-Accent);
  color: map-get($font-colors, Alt);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $padding-large * 3;
  margin-bottom: $padding-large;

  border-radius: $border-radius;
  @include for-size(tablet-landscape-up) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    margin-bottom: 0;
  }
}
