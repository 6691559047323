@import '../variables.module';

@include font-imports('../');

.Root {
  color: map-get($font-colors, Main);
  font-family: map-get($font-family, Sans);
  margin-bottom: $padding-medium;
}

.Info {
  margin-bottom: $padding-large;
}
