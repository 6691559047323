@import '../variables.module';

.Bouquet {
  position: fixed;
  // z-index: 2;

  &.Left {
    bottom: 0;
    left: 0;
    transform: translate(-35%, 30%);
  }

  &.Right {
    top: 0;
    right: 0;
    transform: translate(40%, -35%) scale(-1, -1);
  }

  @include for-size(tablet-landscape-up) {
    &.Left {
      transform: translate(-30%, 35%);
    }
    &.Right {
      transform: translate(30%, -35%) scale(-1, -1);
    }
  }

  @include for-size(desktop-up) {
    &.Left {
      transform: translate(-25%, 30%);
    }
    &.Right {
      top: auto;
      bottom: 0;
      transform: translate(25%, 30%) scale(-1, 1);
    }
  }
}
