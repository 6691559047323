@import '../variables.module.scss';

@include font-imports('../');

.Root {
  // display: flex;
  // width: 100%;
  width: 100vw;
  justify-content: space-around;
  font-family: map-get($font-family, Sans);
  font-size: 2em;
  z-index: 4;
  padding: $padding-small;

  &.Small {
    justify-content: flex-start;
  }

  & .Active {
    border-bottom: 4px solid map-get($font-colors, Alt);
  }

  & .NavLink {
    display: inline-block;
  }
}

.Large {
  display: none;
  background-color: map-get($background-colors, Alt-Accent);

  @include for-size(tablet-landscape-up) {
    display: flex;
  }

  .NavLink {
    color: map-get($font-colors, Alt);
    text-decoration: none;

    &:visited {
      color: map-get($font-colors, Alt);
    }
  }
}

.Small {
  @include for-size(tablet-landscape-up) {
    display: none;
  }

  position: relative;
  color: map-get($font-colors, Alt);

  // width: 100%;
  .NavLink {
    // color: map-get($font-colors, Alt);
    text-decoration: none;

    &:visited {
      color: map-get($font-colors, Alt);
    }
  }

  &.Open {
    flex-direction: column;
    // color: map-get($font-colors, Alt);

    // height: 100%;
    width: 100%;
    // background-color: map-get($background-colors, Main);
    background-color: map-get($background-colors, Alt);
    // justify-content: flex-start;
    // position: fixed;
    left: 0;
    top: 0;
    padding: $padding-small;
    box-sizing: border-box;
    border-bottom: 3px solid map-get($font-colors, Main-Accent);
  }

  & .Close {
    position: absolute;
    right: $padding-small;
    // color: map-get($font-colors, Alt);

    // justify-content: flex-end;
    // float: right;
    // width: 100%;
    // height: 50px;
  }

  // &.Active {
  //   border-bottom: 4px solid map-get($font-colors, Main-Accent);
  // }
}
