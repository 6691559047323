@import '../variables.module';

@include font-imports('../');

.Root {
  // width: 100%;
  // height: 50px;
  align-self: center;
  color: map-get($font-colors, Main);
  // color: map-get($background-colors, Main);
  // background-color: rgba(0, 0, 0, 0);
  // background-color: map-get($font-colors, Main);

  font-family: map-get($font-family, $key: Sans);

  &.Plain {
    font-size: 1.25em;
    border: 2px solid map-get($font-colors, Main-Accent);
    border-radius: $border-radius;
    padding: $padding-small $padding-large * 2;
    background-color: map-get($background-colors, Main);

    &:hover {
      background-color: map-get($background-colors, Alt-Accent);
      color: map-get($font-colors, Alt);
    }
  }

  &.Icon {
    border: none;

    &.Dark {
      color: map-get($font-colors, Alt);
      background-color: map-get($background-colors, Alt);
    }

    &.Light {
      color: map-get($font-colors, Main);
      background-color: map-get($background-colors, Main);

      &:hover {
        color: map-get($font-colors, Main-Accent);
      }
    }
  }

  // border: 1px solid
}
