@import '../variables.module.scss';

@include font-imports('../');

.Root {
  background-color: map-get($background-colors, Alt-Accent);
  // border: 5px solid map-get($background-colors, Alt-Accent);
  margin-bottom: $padding-large;
  border-radius: $border-radius;
  text-align: center;
  padding: $padding-medium;
  font-family: map-get($font-family, Sans);
  color: map-get($font-colors, Alt);
  font-size: 1.5em;
}
