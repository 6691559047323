@import '../../variables.module';

@include font-imports('../../');

.Root {
  margin-bottom: $padding-large * 3;
  font-family: map-get($font-family, Sans);
  font-size: 1.2em;
}

.Heading {
  font-weight: bold;
  font-size: 1.7em;
  font-family: map-get($font-family, Cursive);
  color: map-get($font-colors, Main-Accent);
  // text-decoration: underline;
}

.Postcode {
  font-weight: bold;
  font-size: 1.3em;
  margin: $padding-small 0;
}

.GoogleMapsLinks {
  margin: $padding-small 0;
  font-weight: bold;
  font-size: 1.1em;
}

.Links {
  & a,
  & a:visited {
    text-decoration: none;
    color: inherit; //map-get($font-colors, Main);
  }
  & > button {
    margin: $padding-large $padding-large 0 0;
  }
}
